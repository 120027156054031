<template>
  <div v-if="tfr.test_fit_url !== null" class="m-0">
    <b-card v-if="tfr_is_printing || isPrintMode" class="invoice-preview-card pagebreak m-0" no-body>
      <div class="image-container mt-0">
        <img :src="tfr.test_fit_url" alt="" class="Image mt-0" style="margin-top: -7.5vh !important;"
             :class="tfr.test_fit_orientation !== 'portrait' ? 'rotate' : 'no-rotate'">
      </div>
      <div class="hide_confidential"></div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'

export default {
  props: {
    tfr: {
      type: Object,
    },
    tfr_is_printing:{
      boolean: false,
    },
  },
  components: {
    BCardBody,
    BCard,
  },
  data() {
    return {
      isPrintMode: false,
      newPage: null, // Reference to the dynamically added page
      isLandscapeImage: false,
    }
  },
  mounted() {
    window.addEventListener('beforeprint', this.handleBeforePrint)
    window.addEventListener('afterprint', this.handleAfterPrint)
  },
  beforeDestroy() {
    window.removeEventListener('beforeprint', this.handleBeforePrint)
    window.removeEventListener('afterprint', this.handleAfterPrint)
  },
  methods: {
    handleBeforePrint() {
      this.isPrintMode = true
    },
    handleAfterPrint() {
      this.isPrintMode = false // Remove the dynamically added page after printing
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  page-break-after: always;
  /* Additional styles for the pages */
}

@page {
  size: portrait;
}

@media print {
  .printContent {
    visibility: hidden !important;
  }

  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    //width: 100vh;
    overflow: hidden;
    position: relative;
  }

  .rotate {
    transform: rotate(90deg);
    width: auto;
    max-height: 100vh; /* Limit height to prevent overflow */
    max-width: 90vh; /* Additional width limit for landscape mode */
    position: absolute;
  }

  .no-rotate {
    width: auto;
    max-height: 90vh; /* Limit height to prevent overflow */
    max-width: 100vh; /* Additional width limit for landscape mode */
    position: absolute;
  }

  .hide_confidential {
    background: white;
    position: absolute;
    height: 70px;
    width: 110vh;
    z-index: 10;
    bottom: 50px;
    left: -30px;
  }
}
</style>